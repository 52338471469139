import { JobCategory } from './QuizDefinitions';
import * as images from 'Assets/Images/metier-details';

export enum MetiersCategory {
    all = 'Metiers.Metiers_filter_all_categories',
    forYou = 'Metiers.Metiers_filter_job_for_you',
    serviceAdministratifs = 'Metiers.Metiers_category_services_adminisitratifs',
    gestion = 'Metiers.Metiers_category_gestion',
    servicesDesVentes = 'Metiers.Metiers_category_services_ventes',
    atelierMecanique = 'Metiers.Metiers_category_mecanique',
    atelierCarrosserie = 'Metiers.Metiers_category_carrosserie',
    autres = 'Metiers.Metiers_category_autres',
}

enum JobTitle {
    commis_comptable = 'Metiers.Metiers_jobTitle_commis_comptable',
    controleur_comptable = 'Metiers.Metiers_jobTitle_controleur_comptable',
    rh = 'Metiers.Metiers_jobTitle_rh',
    receptionniste = 'Metiers.Metiers_jobTitle_receptionniste',
    adjoint_administratif = 'Metiers.Metiers_jobTitle_adjoint_administratif',
    commis_comptes_recevoir = 'Metiers.Metiers_jobTitle_commis_comptes_recevoir',
    responsable_sante_securite = 'Metiers.Metiers_jobTitle_responsable_sante_securite',
    service_clientele = 'Metiers.Metiers_jobTitle_service_clientele',
    directeur_commercial = 'Metiers.Metiers_jobTitle_directeur_commercial',
    vp_finances = 'Metiers.Metiers_jobTitle_vp_finances',
    dg = 'Metiers.Metiers_jobTitle_dg',
    directeur_carrosserie = 'Metiers.Metiers_jobTitle_directeur_carrosserie',
    directeur_apres_vente = 'Metiers.Metiers_jobTitle_directeur_apres_vente',
    dg_ventes = 'Metiers.Metiers_jobTitle_dg_ventes',
    directeur_pieces = 'Metiers.Metiers_jobTitle_directeur_pieces',
    directeur_renouvellements = 'Metiers.Metiers_jobTitle_directeur_renouvellements',
    directeur_ventes = 'Metiers.Metiers_jobTitle_directeur_ventes',
    directeur_adjoint_ventes = 'Metiers.Metiers_jobTitle_directeur_adjoint_ventes',
    directeur_operations_fixes = 'Metiers.Metiers_jobTitle_directeur_operations_fixes',
    administrateur_garantie = 'Metiers.Metiers_jobTitle_administrateur_garantie',
    conseiller_vente_camions = 'Metiers.Metiers_jobTitle_conseiller_vente_camions',
    coordonnateur_ventes = 'Metiers.Metiers_jobTitle_coordonnateur_ventes',
    conseiller_vente = 'Metiers.Metiers_jobTitle_conseiller_vente',
    adjoint_direction_ventes = 'Metiers.Metiers_jobTitle_adjoint_direction_ventes',
    secretaires_ventes = 'Metiers.Metiers_jobTitle_secretaires_ventes',
    contremaitre_atelier_mecanique = 'Metiers.Metiers_jobTitle_contremaitre_atelier_mecanique',
    prepose_livraison_pieces = 'Metiers.Metiers_jobTitle_prepose_livraison_pieces',
    adjoint_directions_pieces_operations_fixes = 'Metiers.Metiers_jobTitle_adjoint_directions_pieces_operations_fixes',
    prepose_transport_clients = 'Metiers.Metiers_jobTitle_prepose_transport_clients',
    secretaire_services_apres_vente = 'Metiers.Metiers_jobTitle_secretaire_services_apres_vente',
    mecanicien_apprenti = 'Metiers.Metiers_jobTitle_mecanicien_apprenti',
    mecanicien_compagnon = 'Metiers.Metiers_jobTitle_mecanicien_compagnon',
    prepose_service_apres_vente = 'Metiers.Metiers_jobTitle_prepose_service_apres_vente',
    conseiller_technique = 'Metiers.Metiers_jobTitle_conseiller_technique',
    commis_entrepot = 'Metiers.Metiers_jobTitle_commis_entrepot',
    personne_cour = 'Metiers.Metiers_jobTitle_personne_cour',
    commis_reclamations = 'Metiers.Metiers_jobTitle_commis_reclamations',
    commis_pieces_mecanique = 'Metiers.Metiers_jobTitle_commis_pieces_mecanique',
    contremaitre_atelier_carrosserie = 'Metiers.Metiers_jobTitle_contremaitre_atelier_carrosserie',
    carrossier = 'Metiers.Metiers_jobTitle_carrossier',
    estimateur = 'Metiers.Metiers_jobTitle_estimateur',
    peintre = 'Metiers.Metiers_jobTitle_peintre',
    commis_pieces_carrosserie = 'Metiers.Metiers_jobTitle_commis_pieces_carrosserie',
    prepose_esthetique = 'Metiers.Metiers_jobTitle_prepose_esthetique',
    technicien_mecanique_vehicule_lourds = 'Metiers.Metiers_jobTitle_technicien_mecanique_vehicule_lourds',
    agent_de_liaison = 'Metiers.Metiers_jobTitle_agent_de_liaison',
}

export interface Images {
    small: string;
    large: string;
}
export interface Job {
    title: JobTitle;
    description: {
        p1: string;
        p2: string;
        h2: string;
        h3: string[];
    };
    category: string;
    to: string;
    image: Images;
    testimonyId?: number;
}

export interface RawJob extends Omit<Job, 'title'> {
    title: string;
}
export interface MetiersData {
    categoryType: JobCategory;
    jobs: Job[];
}

const metiersData: MetiersData[] = [
    {
        categoryType: JobCategory.Admin,
        jobs: [
            {
                title: JobTitle.commis_comptable,
                description: {
                    p1: 'Metiers.Metiers_description_commis_comptable_p1',
                    p2: 'Metiers.Metiers_description_commis_comptable_p2',
                    h2: 'Metiers.Metiers_description_commis_comptable_h2',
                    h3: [
                        'Metiers.Metiers_description_commis_comptable_h3_1',
                        'Metiers.Metiers_description_commis_comptable_h3_2',
                        'Metiers.Metiers_description_commis_comptable_h3_3',
                        'Metiers.Metiers_description_commis_comptable_h3_4',
                        'Metiers.Metiers_description_commis_comptable_h3_5',
                        'Metiers.Metiers_description_commis_comptable_h3_6',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.commis_comptable,
                image: {
                    small: images.small_commis_comptable_image,
                    large: images.large_commis_comptable_image,
                },
            },
            {
                title: JobTitle.controleur_comptable,
                description: {
                    p1: 'Metiers.Metiers_description_controleur_comptable_p1',
                    p2: 'Metiers.Metiers_description_controleur_comptable_p2',
                    h2: 'Metiers.Metiers_description_controleur_comptable_h2',
                    h3: [
                        'Metiers.Metiers_description_controleur_comptable_h3_1',
                        'Metiers.Metiers_description_controleur_comptable_h3_2',
                        'Metiers.Metiers_description_controleur_comptable_h3_3',
                        'Metiers.Metiers_description_controleur_comptable_h3_4',
                        'Metiers.Metiers_description_controleur_comptable_h3_5',
                        'Metiers.Metiers_description_controleur_comptable_h3_6',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.controleur_comptable,
                image: {
                    small: images.small_controleur_comptable_image,
                    large: images.large_controleur_comptable_image,
                },
            },
            {
                title: JobTitle.rh,
                description: {
                    p1: 'Metiers.Metiers_description_rh_p1',
                    p2: 'Metiers.Metiers_description_rh_p2',
                    h2: 'Metiers.Metiers_description_rh_h2',
                    h3: [
                        'Metiers.Metiers_description_rh_h3_1',
                        'Metiers.Metiers_description_rh_h3_2',
                        'Metiers.Metiers_description_rh_h3_3',
                        'Metiers.Metiers_description_rh_h3_4',
                        'Metiers.Metiers_description_rh_h3_5',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.rh,
                image: {
                    small: images.small_responsable_rh_image,
                    large: images.large_responsable_rh_image,
                },
            },
            {
                title: JobTitle.receptionniste,
                description: {
                    p1: 'Metiers.Metiers_description_receptionniste_p1',
                    p2: 'Metiers.Metiers_description_receptionniste_p2',
                    h2: 'Metiers.Metiers_description_receptionniste_h2',
                    h3: [
                        'Metiers.Metiers_description_receptionniste_h3_1',
                        'Metiers.Metiers_description_receptionniste_h3_2',
                        'Metiers.Metiers_description_receptionniste_h3_3',
                        'Metiers.Metiers_description_receptionniste_h3_4',
                        'Metiers.Metiers_description_receptionniste_h3_5',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.receptionniste,
                image: {
                    small: images.small_receptionniste_image,
                    large: images.large_receptionniste_image,
                },
            },
            {
                title: JobTitle.adjoint_administratif,
                description: {
                    p1: 'Metiers.Metiers_description_adjoint_administratif_p1',
                    p2: 'Metiers.Metiers_description_adjoint_administratif_p2',
                    h2: 'Metiers.Metiers_description_adjoint_administratif_h2',
                    h3: [
                        'Metiers.Metiers_description_adjoint_administratif_h3_1',
                        'Metiers.Metiers_description_adjoint_administratif_h3_2',
                        'Metiers.Metiers_description_adjoint_administratif_h3_3',
                        'Metiers.Metiers_description_adjoint_administratif_h3_4',
                        'Metiers.Metiers_description_adjoint_administratif_h3_5',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.adjoint_administratif,
                image: {
                    small: images.small_adjoint_administratif_image,
                    large: images.large_adjoint_administratif_image,
                },
            },
            {
                title: JobTitle.commis_comptes_recevoir,
                description: {
                    p1: 'Metiers.Metiers_description_commis_comptes_recevoir_p1',
                    p2: 'Metiers.Metiers_description_commis_comptes_recevoir_p2',
                    h2: 'Metiers.Metiers_description_commis_comptes_recevoir_h2',
                    h3: [
                        'Metiers.Metiers_description_commis_comptes_recevoir_h3_1',
                        'Metiers.Metiers_description_commis_comptes_recevoir_h3_2',
                        'Metiers.Metiers_description_commis_comptes_recevoir_h3_3',
                        'Metiers.Metiers_description_commis_comptes_recevoir_h3_4',
                        'Metiers.Metiers_description_commis_comptes_recevoir_h3_5',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.commis_comptes_recevoir,
                image: {
                    small: images.small_commis_comptes_a_recevoir_image,
                    large: images.large_commis_comptes_a_recevoir_image,
                },
            },
            {
                title: JobTitle.responsable_sante_securite,
                description: {
                    p1: 'Metiers.Metiers_description_responsable_sante_securite_p1',
                    p2: 'Metiers.Metiers_description_responsable_sante_securite_p2',
                    h2: 'Metiers.Metiers_description_responsable_sante_securite_h2',
                    h3: [
                        'Metiers.Metiers_description_responsable_sante_securite_h3_1',
                        'Metiers.Metiers_description_responsable_sante_securite_h3_2',
                        'Metiers.Metiers_description_responsable_sante_securite_h3_3',
                        'Metiers.Metiers_description_responsable_sante_securite_h3_4',
                        'Metiers.Metiers_description_responsable_sante_securite_h3_5',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.responsable_sante_securite,
                image: {
                    small: images.small_responsable_sante_securite_image,
                    large: images.large_responsable_sante_securite_image,
                },
            },
            {
                title: JobTitle.service_clientele,
                description: {
                    p1: 'Metiers.Metiers_description_service_clientele_p1',
                    p2: 'Metiers.Metiers_description_service_clientele_p2',
                    h2: 'Metiers.Metiers_description_service_clientele_h2',
                    h3: [
                        'Metiers.Metiers_description_service_clientele_h3_1',
                        'Metiers.Metiers_description_service_clientele_h3_2',
                        'Metiers.Metiers_description_service_clientele_h3_3',
                        'Metiers.Metiers_description_service_clientele_h3_4',
                        'Metiers.Metiers_description_service_clientele_h3_5',
                        'Metiers.Metiers_description_service_clientele_h3_6',
                    ],
                },
                category: `Metiers.Metiers_category_services_adminisitratifs`,
                to: JobTitle.service_clientele,
                image: {
                    small: images.small_agent_appel_image,
                    large: images.large_agent_appel_image,
                },
            },
        ],
    },
    {
        categoryType: JobCategory.Management,
        jobs: [
            {
                title: JobTitle.vp_finances,
                description: {
                    p1: 'Metiers.Metiers_description_vp_finances_p1',
                    p2: 'Metiers.Metiers_description_vp_finances_p2',
                    h2: 'Metiers.Metiers_description_vp_finances_h2',
                    h3: [
                        'Metiers.Metiers_description_vp_finances_h3_1',
                        'Metiers.Metiers_description_vp_finances_h3_2',
                        'Metiers.Metiers_description_vp_finances_h3_3',
                        'Metiers.Metiers_description_vp_finances_h3_4',
                        'Metiers.Metiers_description_vp_finances_h3_5',
                        'Metiers.Metiers_description_vp_finances_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.vp_finances,
                image: {
                    small: images.small_vp_finances_image,
                    large: images.large_vp_finances_image,
                },
            },
            {
                title: JobTitle.dg,
                description: {
                    p1: 'Metiers.Metiers_description_dg_p1',
                    p2: 'Metiers.Metiers_description_dg_p2',
                    h2: 'Metiers.Metiers_description_dg_h2',
                    h3: [
                        'Metiers.Metiers_description_dg_h3_1',
                        'Metiers.Metiers_description_dg_h3_2',
                        'Metiers.Metiers_description_dg_h3_3',
                        'Metiers.Metiers_description_dg_h3_4',
                        'Metiers.Metiers_description_dg_h3_5',
                        'Metiers.Metiers_description_dg_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.dg,
                image: {
                    small: images.small_directeur_general_image,
                    large: images.large_directeur_general_image,
                },
                testimonyId: 3,
            },
            {
                title: JobTitle.directeur_carrosserie,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_carrosserie_p1',
                    p2: 'Metiers.Metiers_description_directeur_carrosserie_p2',
                    h2: 'Metiers.Metiers_description_directeur_carrosserie_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_carrosserie_h3_1',
                        'Metiers.Metiers_description_directeur_carrosserie_h3_2',
                        'Metiers.Metiers_description_directeur_carrosserie_h3_3',
                        'Metiers.Metiers_description_directeur_carrosserie_h3_4',
                        'Metiers.Metiers_description_directeur_carrosserie_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_carrosserie,
                image: {
                    small: images.small_directeur_carrosserie_image,
                    large: images.large_directeur_carrosserie_image,
                },
            },
            {
                title: JobTitle.directeur_apres_vente,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_apres_vente_p1',
                    p2: 'Metiers.Metiers_description_directeur_apres_vente_p2',
                    h2: 'Metiers.Metiers_description_directeur_apres_vente_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_apres_vente_h3_1',
                        'Metiers.Metiers_description_directeur_apres_vente_h3_2',
                        'Metiers.Metiers_description_directeur_apres_vente_h3_3',
                        'Metiers.Metiers_description_directeur_apres_vente_h3_4',
                        'Metiers.Metiers_description_directeur_apres_vente_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_apres_vente,
                image: {
                    small: images.small_directeur_service_apres_vente_image,
                    large: images.large_directeur_service_apres_vente_image,
                },
            },
            {
                title: JobTitle.dg_ventes,
                description: {
                    p1: 'Metiers.Metiers_description_dg_ventes_p1',
                    p2: 'Metiers.Metiers_description_dg_ventes_p2',
                    h2: 'Metiers.Metiers_description_dg_ventes_h2',
                    h3: [
                        'Metiers.Metiers_description_dg_ventes_h3_1',
                        'Metiers.Metiers_description_dg_ventes_h3_2',
                        'Metiers.Metiers_description_dg_ventes_h3_3',
                        'Metiers.Metiers_description_dg_ventes_h3_4',
                        'Metiers.Metiers_description_dg_ventes_h3_5',
                        'Metiers.Metiers_description_dg_ventes_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.dg_ventes,
                image: {
                    small: images.small_directeur_ventes_image,
                    large: images.large_directeur_ventes_image,
                },
                testimonyId: 4,
            },
            {
                title: JobTitle.directeur_pieces,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_pieces_p1',
                    p2: 'Metiers.Metiers_description_directeur_pieces_p2',
                    h2: 'Metiers.Metiers_description_directeur_pieces_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_pieces_h3_1',
                        'Metiers.Metiers_description_directeur_pieces_h3_2',
                        'Metiers.Metiers_description_directeur_pieces_h3_3',
                        'Metiers.Metiers_description_directeur_pieces_h3_4',
                        'Metiers.Metiers_description_directeur_pieces_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_pieces,
                image: {
                    small: images.small_directeur_pieces_image,
                    large: images.large_directeur_pieces_image,
                },
            },
            {
                title: JobTitle.directeur_renouvellements,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_renouvellements_p1',
                    p2: 'Metiers.Metiers_description_directeur_renouvellements_p2',
                    h2: 'Metiers.Metiers_description_directeur_renouvellements_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_renouvellements_h3_1',
                        'Metiers.Metiers_description_directeur_renouvellements_h3_2',
                        'Metiers.Metiers_description_directeur_renouvellements_h3_3',
                        'Metiers.Metiers_description_directeur_renouvellements_h3_4',
                        'Metiers.Metiers_description_directeur_renouvellements_h3_5',
                        'Metiers.Metiers_description_directeur_renouvellements_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_renouvellements,
                image: {
                    small: images.small_directeur_renouvellements_image,
                    large: images.large_directeur_renouvellement_image,
                },
            },
            {
                title: JobTitle.directeur_ventes,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_ventes_p1',
                    p2: 'Metiers.Metiers_description_directeur_ventes_p2',
                    h2: 'Metiers.Metiers_description_directeur_ventes_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_ventes_h3_1',
                        'Metiers.Metiers_description_directeur_ventes_h3_2',
                        'Metiers.Metiers_description_directeur_ventes_h3_3',
                        'Metiers.Metiers_description_directeur_ventes_h3_4',
                        'Metiers.Metiers_description_directeur_ventes_h3_5',
                        'Metiers.Metiers_description_directeur_ventes_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_ventes,
                image: {
                    small: images.small_directeur_ventes_image,
                    large: images.large_directeur_ventes_image,
                },
            },
            {
                title: JobTitle.directeur_adjoint_ventes,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_adjoint_ventes_p1',
                    p2: 'Metiers.Metiers_description_directeur_adjoint_ventes_p2',
                    h2: 'Metiers.Metiers_description_directeur_adjoint_ventes_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_adjoint_ventes_h3_1',
                        'Metiers.Metiers_description_directeur_adjoint_ventes_h3_2',
                        'Metiers.Metiers_description_directeur_adjoint_ventes_h3_3',
                        'Metiers.Metiers_description_directeur_adjoint_ventes_h3_4',
                        'Metiers.Metiers_description_directeur_adjoint_ventes_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_adjoint_ventes,
                image: {
                    small: images.small_directeur_adjoint_ventes_image,
                    large: images.large_directeur_adjoint_ventes_image,
                },
            },
            {
                title: JobTitle.directeur_operations_fixes,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_operations_fixes_p1',
                    p2: 'Metiers.Metiers_description_directeur_operations_fixes_p2',
                    h2: 'Metiers.Metiers_description_directeur_operations_fixes_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_1',
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_2',
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_3',
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_4',
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_5',
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_6',
                        'Metiers.Metiers_description_directeur_operations_fixes_h3_7',
                    ],
                },
                category: 'Metiers.Metiers_category_gestion',
                to: JobTitle.directeur_operations_fixes,
                image: {
                    small: images.small_directeur_operations_fixes_image,
                    large: images.large_directeur_operations_fixes_image,
                },
            },
        ],
    },
    {
        categoryType: JobCategory.Sales,
        jobs: [
            {
                title: JobTitle.administrateur_garantie,
                description: {
                    p1: 'Metiers.Metiers_description_administrateur_garantie_p1',
                    p2: 'Metiers.Metiers_description_administrateur_garantie_p2',
                    h2: 'Metiers.Metiers_description_administrateur_garantie_h2',
                    h3: [
                        'Metiers.Metiers_description_administrateur_garantie_h3_1',
                        'Metiers.Metiers_description_administrateur_garantie_h3_2',
                        'Metiers.Metiers_description_administrateur_garantie_h3_3',
                        'Metiers.Metiers_description_administrateur_garantie_h3_4',
                        'Metiers.Metiers_description_administrateur_garantie_h3_5',
                        'Metiers.Metiers_description_administrateur_garantie_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.administrateur_garantie,
                image: {
                    small: images.small_administrateur_garantie_image,
                    large: images.large_administrateur_garantie_image,
                },
            },
            {
                title: JobTitle.conseiller_vente_camions,
                description: {
                    p1: 'Metiers.Metiers_description_conseiller_ventes_camions_p1',
                    p2: 'Metiers.Metiers_description_conseiller_ventes_camions_p2',
                    h2: 'Metiers.Metiers_description_conseiller_ventes_camions_h2',
                    h3: [
                        'Metiers.Metiers_description_conseiller_ventes_camions_h3_1',
                        'Metiers.Metiers_description_conseiller_ventes_camions_h3_2',
                        'Metiers.Metiers_description_conseiller_ventes_camions_h3_3',
                        'Metiers.Metiers_description_conseiller_ventes_camions_h3_4',
                        'Metiers.Metiers_description_conseiller_ventes_camions_h3_5',
                        'Metiers.Metiers_description_conseiller_ventes_camions_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.conseiller_vente_camions,
                image: {
                    small: images.small_conseiller_vente_camion_image,
                    large: images.large_conseiller_vente_camions_image,
                },
            },
            {
                title: JobTitle.coordonnateur_ventes,
                description: {
                    p1: 'Metiers.Metiers_description_coordonnateur_ventes_p1',
                    p2: 'Metiers.Metiers_description_coordonnateur_ventes_p2',
                    h2: 'Metiers.Metiers_description_coordonnateur_ventes_h2',
                    h3: [
                        'Metiers.Metiers_description_coordonnateur_ventes_h3_1',
                        'Metiers.Metiers_description_coordonnateur_ventes_h3_2',
                        'Metiers.Metiers_description_coordonnateur_ventes_h3_3',
                        'Metiers.Metiers_description_coordonnateur_ventes_h3_4',
                        'Metiers.Metiers_description_coordonnateur_ventes_h3_5',
                        'Metiers.Metiers_description_coordonnateur_ventes_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.coordonnateur_ventes,
                image: {
                    small: images.small_coordonateur_ventes_image,
                    large: images.large_coordonateur_ventes_image,
                },
            },
            {
                title: JobTitle.conseiller_vente,
                description: {
                    p1: 'Metiers.Metiers_description_conseiller_vente_p1',
                    p2: 'Metiers.Metiers_description_conseiller_vente_p2',
                    h2: 'Metiers.Metiers_description_conseiller_vente_h2',
                    h3: [
                        'Metiers.Metiers_description_conseiller_vente_h3_1',
                        'Metiers.Metiers_description_conseiller_vente_h3_2',
                        'Metiers.Metiers_description_conseiller_vente_h3_3',
                        'Metiers.Metiers_description_conseiller_vente_h3_4',
                        'Metiers.Metiers_description_conseiller_vente_h3_5',
                        'Metiers.Metiers_description_conseiller_vente_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.conseiller_vente,
                image: {
                    small: images.small_conseiller_ventes_image,
                    large: images.small_conseiller_ventes_image,
                },
            },
            {
                title: JobTitle.adjoint_direction_ventes,
                description: {
                    p1: 'Metiers.Metiers_description_adjoint_direction_ventes_p1',
                    p2: 'Metiers.Metiers_description_adjoint_direction_ventes_p2',
                    h2: 'Metiers.Metiers_description_adjoint_direction_ventes_h2',
                    h3: [
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_1',
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_2',
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_3',
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_4',
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_5',
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_6',
                        'Metiers.Metiers_description_adjoint_direction_ventes_h3_7',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.adjoint_direction_ventes,
                image: {
                    small: images.small_adjoint_direction_ventes_image,
                    large: images.large_adjoint_directeur_ventes_image,
                },
            },
            {
                title: JobTitle.secretaires_ventes,
                description: {
                    p1: 'Metiers.Metiers_description_secretaire_ventes_p1',
                    p2: 'Metiers.Metiers_description_secretaire_ventes_p2',
                    h2: 'Metiers.Metiers_description_secretaire_ventes_h2',
                    h3: [
                        'Metiers.Metiers_description_secretaire_ventes_h3_1',
                        'Metiers.Metiers_description_secretaire_ventes_h3_2',
                        'Metiers.Metiers_description_secretaire_ventes_h3_3',
                        'Metiers.Metiers_description_secretaire_ventes_h3_4',
                        'Metiers.Metiers_description_secretaire_ventes_h3_5',
                        'Metiers.Metiers_description_secretaire_ventes_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.secretaires_ventes,
                image: {
                    small: images.small_secretaire_ventes_image,
                    large: images.large_secretaire_ventes_image,
                },
            },
            {
                title: JobTitle.directeur_commercial,
                description: {
                    p1: 'Metiers.Metiers_description_directeur_commercial_p1',
                    p2: 'Metiers.Metiers_description_directeur_commercial_p2',
                    h2: 'Metiers.Metiers_description_directeur_commercial_h2',
                    h3: [
                        'Metiers.Metiers_description_directeur_commercial_h3_1',
                        'Metiers.Metiers_description_directeur_commercial_h3_2',
                        'Metiers.Metiers_description_directeur_commercial_h3_3',
                        'Metiers.Metiers_description_directeur_commercial_h3_4',
                        'Metiers.Metiers_description_directeur_commercial_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_services_ventes',
                to: JobTitle.directeur_commercial,
                image: {
                    small: images.small_directeur_commercial_image,
                    large: images.large_directeur_commercial_image,
                },
                testimonyId: 1,
            },
        ],
    },
    {
        categoryType: JobCategory.Mechanical,
        jobs: [
            {
                title: JobTitle.contremaitre_atelier_mecanique,
                description: {
                    p1: 'Metiers.Metiers_description_contremaitre_atelier_mecanique_p1',
                    p2: 'Metiers.Metiers_description_contremaitre_atelier_mecanique_p2',
                    h2: 'Metiers.Metiers_description_contremaitre_atelier_mecanique_h2',
                    h3: [
                        'Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_1',
                        'Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_2',
                        'Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_3',
                        'Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_4',
                        'Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_5',
                        'Metiers.Metiers_description_contremaitre_atelier_mecanique_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.contremaitre_atelier_mecanique,
                image: {
                    small: images.small_contremaitre_atelier_mecanique_image,
                    large: images.large_contremaitre_atelier_mecanique_image,
                },
            },
            {
                title: JobTitle.prepose_livraison_pieces,
                description: {
                    p1: 'Metiers.Metiers_description_prepose_livraison_pieces_p1',
                    p2: 'Metiers.Metiers_description_prepose_livraison_pieces_p2',
                    h2: 'Metiers.Metiers_description_prepose_livraison_pieces_h2',
                    h3: [
                        'Metiers.Metiers_description_prepose_livraison_pieces_h3_1',
                        'Metiers.Metiers_description_prepose_livraison_pieces_h3_2',
                        'Metiers.Metiers_description_prepose_livraison_pieces_h3_3',
                        'Metiers.Metiers_description_prepose_livraison_pieces_h3_4',
                        'Metiers.Metiers_description_prepose_livraison_pieces_h3_5',
                        'Metiers.Metiers_description_prepose_livraison_pieces_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.prepose_livraison_pieces,
                image: {
                    small: images.small_prepose_livraison_pieces_image,
                    large: images.large_prepose_livraison_pieces_image,
                },
            },
            {
                title: JobTitle.adjoint_directions_pieces_operations_fixes,
                description: {
                    p1: 'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_p1',
                    p2: 'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_p2',
                    h2: 'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h2',
                    h3: [
                        'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_1',
                        'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_2',
                        'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_3',
                        'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_4',
                        'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_5',
                        'Metiers.Metiers_description_adjoint_direction_pieces_operations_fixes_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.adjoint_directions_pieces_operations_fixes,
                image: {
                    small: images.small_adjoint_direction_operations_fixes_image,
                    large: images.large_adjoint_direction_operations_fixes_image,
                },
            },
            {
                title: JobTitle.prepose_transport_clients,
                description: {
                    p1: 'Metiers.Metiers_description_prepose_transport_clients_p1',
                    p2: 'Metiers.Metiers_description_prepose_transport_clients_p2',
                    h2: 'Metiers.Metiers_description_prepose_transport_clients_h2',
                    h3: [
                        'Metiers.Metiers_description_prepose_transport_clients_h3_1',
                        'Metiers.Metiers_description_prepose_transport_clients_h3_2',
                        'Metiers.Metiers_description_prepose_transport_clients_h3_3',
                        'Metiers.Metiers_description_prepose_transport_clients_h3_4',
                        'Metiers.Metiers_description_prepose_transport_clients_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.prepose_transport_clients,
                image: {
                    small: images.small_prepose_transport_clients_image,
                    large: images.large_prepose_transport_clients_image,
                },
            },
            {
                title: JobTitle.secretaire_services_apres_vente,
                description: {
                    p1: 'Metiers.Metiers_description_secretaire_services_apres_vente_p1',
                    p2: 'Metiers.Metiers_description_secretaire_services_apres_vente_p2',
                    h2: 'Metiers.Metiers_description_secretaire_services_apres_vente_h2',
                    h3: [
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_1',
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_2',
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_3',
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_4',
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_5',
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_6',
                        'Metiers.Metiers_description_secretaire_services_apres_vente_h3_7',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.secretaire_services_apres_vente,
                image: {
                    small: images.small_secretaire_service_apres_vente_image,
                    large: images.large_secretaire_service_apres_vente_image,
                },
            },
            {
                title: JobTitle.mecanicien_apprenti,
                description: {
                    p1: 'Metiers.Metiers_description_mecanicien_apprenti_p1',
                    p2: 'Metiers.Metiers_description_mecanicien_apprenti_p2',
                    h2: 'Metiers.Metiers_description_mecanicien_apprenti_h2',
                    h3: [
                        'Metiers.Metiers_description_mecanicien_apprenti_h3_1',
                        'Metiers.Metiers_description_mecanicien_apprenti_h3_2',
                        'Metiers.Metiers_description_mecanicien_apprenti_h3_3',
                        'Metiers.Metiers_description_mecanicien_apprenti_h3_4',
                        'Metiers.Metiers_description_mecanicien_apprenti_h3_5',
                        'Metiers.Metiers_description_mecanicien_apprenti_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.mecanicien_apprenti,
                image: {
                    small: images.small_mecanicien_apprenti_image,
                    large: images.large_mecanicien_apprenti_image,
                },
            },
            {
                title: JobTitle.mecanicien_compagnon,
                description: {
                    p1: 'Metiers.Metiers_description_mecanicien_compagnon_p1',
                    p2: 'Metiers.Metiers_description_mecanicien_compagnon_p2',
                    h2: 'Metiers.Metiers_description_mecanicien_compagnon_h2',
                    h3: [
                        'Metiers.Metiers_description_mecanicien_compagnon_h3_1',
                        'Metiers.Metiers_description_mecanicien_compagnon_h3_2',
                        'Metiers.Metiers_description_mecanicien_compagnon_h3_3',
                        'Metiers.Metiers_description_mecanicien_compagnon_h3_4',
                        'Metiers.Metiers_description_mecanicien_compagnon_h3_5',
                        'Metiers.Metiers_description_mecanicien_compagnon_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.mecanicien_compagnon,
                image: {
                    small: images.small_mecanicien_compagnon_image,
                    large: images.large_mecanicien_compagnon_image,
                },
            },
            {
                title: JobTitle.prepose_service_apres_vente,
                description: {
                    p1: 'Metiers.Metiers_description_prepose_service_apres_vente_p1',
                    p2: 'Metiers.Metiers_description_prepose_service_apres_vente_p2',
                    h2: 'Metiers.Metiers_description_prepose_service_apres_vente_h2',
                    h3: [
                        'Metiers.Metiers_description_prepose_service_apres_vente_h3_1',
                        'Metiers.Metiers_description_prepose_service_apres_vente_h3_2',
                        'Metiers.Metiers_description_prepose_service_apres_vente_h3_3',
                        'Metiers.Metiers_description_prepose_service_apres_vente_h3_4',
                        'Metiers.Metiers_description_prepose_service_apres_vente_h3_5',
                        'Metiers.Metiers_description_prepose_service_apres_vente_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.prepose_service_apres_vente,
                image: {
                    small: images.small_prepose_service_apres_vente_image,
                    large: images.large_prepose_service_apres_vente_image,
                },
            },
            {
                title: JobTitle.conseiller_technique,
                description: {
                    p1: 'Metiers.Metiers_description_conseiller_technique_p1',
                    p2: 'Metiers.Metiers_description_conseiller_technique_p2',
                    h2: 'Metiers.Metiers_description_conseiller_technique_h2',
                    h3: [
                        'Metiers.Metiers_description_conseiller_technique_h3_1',
                        'Metiers.Metiers_description_conseiller_technique_h3_2',
                        'Metiers.Metiers_description_conseiller_technique_h3_3',
                        'Metiers.Metiers_description_conseiller_technique_h3_4',
                        'Metiers.Metiers_description_conseiller_technique_h3_5',
                        'Metiers.Metiers_description_conseiller_technique_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.conseiller_technique,
                image: {
                    small: images.small_conseiller_technique_image,
                    large: images.large_conseiller_technique_image,
                },
            },
            {
                title: JobTitle.commis_entrepot,
                description: {
                    p1: 'Metiers.Metiers_description_commis_entrepot_p1',
                    p2: 'Metiers.Metiers_description_commis_entrepot_p2',
                    h2: 'Metiers.Metiers_description_commis_entrepot_h2',
                    h3: [
                        'Metiers.Metiers_description_commis_entrepot_h3_1',
                        'Metiers.Metiers_description_commis_entrepot_h3_2',
                        'Metiers.Metiers_description_commis_entrepot_h3_3',
                        'Metiers.Metiers_description_commis_entrepot_h3_4',
                        'Metiers.Metiers_description_commis_entrepot_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.commis_entrepot,
                image: {
                    small: images.small_commis_entrepot_image,
                    large: images.large_commis_entrepot_image,
                },
            },
            {
                title: JobTitle.personne_cour,
                description: {
                    p1: 'Metiers.Metiers_description_personne_cour_p1',
                    p2: 'Metiers.Metiers_description_personne_cour_p2',
                    h2: 'Metiers.Metiers_description_personne_cour_h2',
                    h3: [
                        'Metiers.Metiers_description_personne_cour_h3_1',
                        'Metiers.Metiers_description_personne_cour_h3_2',
                        'Metiers.Metiers_description_personne_cour_h3_3',
                        'Metiers.Metiers_description_personne_cour_h3_4',
                        'Metiers.Metiers_description_personne_cour_h3_5',
                        'Metiers.Metiers_description_personne_cour_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.personne_cour,
                image: {
                    small: images.small_personne_de_cour_image,
                    large: images.large_personne_de_cour_image,
                },
            },

            {
                title: JobTitle.commis_reclamations,
                description: {
                    p1: 'Metiers.Metiers_description_commis_reclamations_p1',
                    p2: 'Metiers.Metiers_description_commis_reclamations_p2',
                    h2: 'Metiers.Metiers_description_commis_reclamations_h2',
                    h3: [
                        'Metiers.Metiers_description_commis_reclamations_h3_1',
                        'Metiers.Metiers_description_commis_reclamations_h3_2',
                        'Metiers.Metiers_description_commis_reclamations_h3_3',
                        'Metiers.Metiers_description_commis_reclamations_h3_4',
                        'Metiers.Metiers_description_commis_reclamations_h3_5',
                        'Metiers.Metiers_description_commis_reclamations_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.commis_reclamations,
                image: {
                    small: images.small_commis_reclamations_image,
                    large: images.large_commis_reclamations_image,
                },
            },
            {
                title: JobTitle.commis_pieces_mecanique,
                description: {
                    p1: 'Metiers.Metiers_description_commis_pieces_mecanique_p1',
                    p2: 'Metiers.Metiers_description_commis_pieces_mecanique_p2',
                    h2: 'Metiers.Metiers_description_commis_pieces_mecanique_h2',
                    h3: [
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_1',
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_2',
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_3',
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_4',
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_5',
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_6',
                        'Metiers.Metiers_description_commis_pieces_mecanique_h3_7',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.commis_pieces_mecanique,
                image: {
                    small: images.small_commis_pieces_mecanique_image,
                    large: images.large_commis_pieces_mecanique_image,
                },
                testimonyId: 2,
            },
            {
                title: JobTitle.technicien_mecanique_vehicule_lourds,
                description: {
                    p1: 'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_p1',
                    p2: 'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_p2',
                    h2: 'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h2',
                    h3: [
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_1',
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_2',
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_3',
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_4',
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_5',
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_6',
                        'Metiers.Metiers_description_technicien_mecanique_vehicule_lourds_h3_7',
                    ],
                },
                category: 'Metiers.Metiers_category_mecanique',
                to: JobTitle.technicien_mecanique_vehicule_lourds,
                image: {
                    small: images.small_mecanicien_vehicule_lours_image,
                    large: images.large_mecanicien_vehicule_lours_image,
                },
            },
        ],
    },
    {
        categoryType: JobCategory.BodyShop,
        jobs: [
            {
                title: JobTitle.contremaitre_atelier_carrosserie,
                description: {
                    p1: 'Metiers.Metiers_description_contremaitre_atelier_carrosserie_p1',
                    p2: 'Metiers.Metiers_description_contremaitre_atelier_carrosserie_p2',
                    h2: 'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h2',
                    h3: [
                        'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_1',
                        'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_2',
                        'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_3',
                        'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_4',
                        'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_5',
                        'Metiers.Metiers_description_contremaitre_atelier_carrosserie_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_carrosserie',
                to: JobTitle.contremaitre_atelier_carrosserie,
                image: {
                    small: images.small_contremaitre_atelier_carrosserie_image,
                    large: images.large_contremaitre_atelier_carrosserie_image,
                },
            },
            {
                title: JobTitle.carrossier,
                description: {
                    p1: 'Metiers.Metiers_description_carrossier_p1',
                    p2: 'Metiers.Metiers_description_carrossier_p2',
                    h2: 'Metiers.Metiers_description_carrossier_h2',
                    h3: [
                        'Metiers.Metiers_description_carrossier_h3_1',
                        'Metiers.Metiers_description_carrossier_h3_2',
                        'Metiers.Metiers_description_carrossier_h3_3',
                        'Metiers.Metiers_description_carrossier_h3_4',
                        'Metiers.Metiers_description_carrossier_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_carrosserie',
                to: JobTitle.carrossier,
                image: {
                    small: images.small_carrossier_image,
                    large: images.large_carrossier_image,
                },
            },
            {
                title: JobTitle.estimateur,
                description: {
                    p1: 'Metiers.Metiers_description_estimateur_p1',
                    p2: 'Metiers.Metiers_description_estimateur_p2',
                    h2: 'Metiers.Metiers_description_estimateur_h2',
                    h3: [
                        'Metiers.Metiers_description_estimateur_h3_1',
                        'Metiers.Metiers_description_estimateur_h3_2',
                        'Metiers.Metiers_description_estimateur_h3_3',
                        'Metiers.Metiers_description_estimateur_h3_4',
                        'Metiers.Metiers_description_estimateur_h3_5',
                        'Metiers.Metiers_description_estimateur_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_carrosserie',
                to: JobTitle.estimateur,
                image: {
                    small: images.small_estimateur_image,
                    large: images.large_estimateur_image,
                },
            },
            {
                title: JobTitle.peintre,
                description: {
                    p1: 'Metiers.Metiers_description_peintre_p1',
                    p2: 'Metiers.Metiers_description_peintre_p2',
                    h2: 'Metiers.Metiers_description_peintre_h2',
                    h3: [
                        'Metiers.Metiers_description_peintre_h3_1',
                        'Metiers.Metiers_description_peintre_h3_2',
                        'Metiers.Metiers_description_peintre_h3_3',
                        'Metiers.Metiers_description_peintre_h3_4',
                        'Metiers.Metiers_description_peintre_h3_5',
                        'Metiers.Metiers_description_peintre_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_carrosserie',
                to: JobTitle.peintre,
                image: {
                    small: images.small_peintre_image,
                    large: images.large_peintre_image,
                },
            },
            {
                title: JobTitle.commis_pieces_carrosserie,
                description: {
                    p1: 'Metiers.Metiers_description_commis_pieces_carrosserie_p1',
                    p2: 'Metiers.Metiers_description_commis_pieces_carrosserie_p2',
                    h2: 'Metiers.Metiers_description_commis_pieces_carrosserie_h2',
                    h3: [
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_1',
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_2',
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_3',
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_4',
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_5',
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_6',
                        'Metiers.Metiers_description_commis_pieces_carrosserie_h3_7',
                    ],
                },
                category: 'Metiers.Metiers_category_carrosserie',
                to: JobTitle.commis_pieces_carrosserie,
                image: {
                    small: images.small_commis_pieces_carrosserie_image,
                    large: images.large_commis_pieces_carrosserie_image,
                },
            },
            {
                title: JobTitle.agent_de_liaison,
                description: {
                    p1: 'Metiers.Metiers_description_agent_de_liaison_p1',
                    p2: 'Metiers.Metiers_description_agent_de_liaison_p2',
                    h2: 'Metiers.Metiers_description_agent_de_liaison_h2',
                    h3: [
                        'Metiers.Metiers_description_agent_de_liaison_h3_1',
                        'Metiers.Metiers_description_agent_de_liaison_h3_2',
                        'Metiers.Metiers_description_agent_de_liaison_h3_3',
                        'Metiers.Metiers_description_agent_de_liaison_h3_4',
                        'Metiers.Metiers_description_agent_de_liaison_h3_5',
                    ],
                },
                category: 'Metiers.Metiers_category_carrosserie',
                to: JobTitle.agent_de_liaison,
                image: {
                    small: images.small_agent_liaison_image,
                    large: images.large_agent_liaison_image,
                },
            },
        ],
    },
    {
        categoryType: JobCategory.Other,
        jobs: [
            {
                title: JobTitle.prepose_esthetique,
                description: {
                    p1: 'Metiers.Metiers_description_prepose_esthetique_p1',
                    p2: 'Metiers.Metiers_description_prepose_esthetique_p2',
                    h2: 'Metiers.Metiers_description_prepose_esthetique_h2',
                    h3: [
                        'Metiers.Metiers_description_prepose_esthetique_h3_1',
                        'Metiers.Metiers_description_prepose_esthetique_h3_2',
                        'Metiers.Metiers_description_prepose_esthetique_h3_3',
                        'Metiers.Metiers_description_prepose_esthetique_h3_4',
                        'Metiers.Metiers_description_prepose_esthetique_h3_5',
                        'Metiers.Metiers_description_prepose_esthetique_h3_6',
                    ],
                },
                category: 'Metiers.Metiers_category_autres',
                to: JobTitle.prepose_esthetique,
                image: {
                    small: images.small_prepose_esthetique_image,
                    large: images.large_prepose_esthetique_image,
                },
            },
        ],
    },
];

export default metiersData;
