// GENERATED FILE - DO NOT MODIFY
import { ConcessionDetailsDto } from 'Api/Features/Concessions/Dtos/ConcessionDetailsDto';
import { ConcessionPublicDetailsDto } from 'Api/Features/Concessions/Dtos/ConcessionPublicDetailsDto';
import { ConcessionRecruitmentFeedbackDto } from 'Api/Features/Concessions/Dtos/ConcessionRecruitmentFeedbackDto';
import { GetConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicRequestDto';
import { GetConcessionsPublicResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicResponseDto';
import { GetConcessionsRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsRequestDto';
import { GetConcessionsResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsResponseDto';
import { GetMakesResponseDto } from 'Api/Features/Concessions/Dtos/GetMakesResponseDto';
import { GetRecruitmentConcessionsRequestDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsRequestDto';
import { GetRecruitmentConcessionsResponseDto } from 'Api/Features/Concessions/Dtos/GetRecruitmentConcessionsResponseDto';
import { UpdateConcessionRecruitmentFeedbackRequestDto } from 'Api/Features/Concessions/Dtos/UpdateConcessionRecruitmentFeedbackRequestDto';
import { UpdateConcessionRecruitmentInfoRequestDto } from 'Api/Features/Concessions/Dtos/UpdateConcessionRecruitmentInfoRequestDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ConcessionProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getConcession(concessionId: string): Promise<ConcessionDetailsDto | null> {
        const uri = this.buildUri(
            "/concessions/{concessionId}",
            { concessionId: concessionId },
            null
        );

        const data: ConcessionDetailsDto | null = await this.httpClient.get<ConcessionDetailsDto | null>(uri);
        return data;
    }

    public async getConcessions(request: GetConcessionsRequestDto | null): Promise<GetConcessionsResponseDto | null> {
        const uri = this.buildUri(
            "/concessions",
            null,
            { ...request || undefined }
        );

        const data: GetConcessionsResponseDto | null = await this.httpClient.get<GetConcessionsResponseDto | null>(uri);
        return data;
    }

    public async updateConcessionRecruitmentInfo(concessionId: string, request: UpdateConcessionRecruitmentInfoRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/concessions/{concessionId}/recruitment-info",
            { concessionId: concessionId },
            null
        );

        await this.httpClient.patch<UpdateConcessionRecruitmentInfoRequestDto | null, void>(uri, request);
    }

    public async getRecruitmentConcessions(request: GetRecruitmentConcessionsRequestDto | null): Promise<GetRecruitmentConcessionsResponseDto | null> {
        const uri = this.buildUri(
            "/recruitment-concessions",
            null,
            { ...request || undefined }
        );

        const data: GetRecruitmentConcessionsResponseDto | null = await this.httpClient.get<GetRecruitmentConcessionsResponseDto | null>(uri);
        return data;
    }

    public async getConcessionPublic(concessionId: string): Promise<ConcessionPublicDetailsDto | null> {
        const uri = this.buildUri(
            "/concessions-public/{concessionId}",
            { concessionId: concessionId },
            null
        );

        const data: ConcessionPublicDetailsDto | null = await this.httpClient.get<ConcessionPublicDetailsDto | null>(uri);
        return data;
    }

    public async getConcessionsPublic(request: GetConcessionsPublicRequestDto | null): Promise<GetConcessionsPublicResponseDto | null> {
        const uri = this.buildUri(
            "/concessions-public",
            null,
            { ...request || undefined }
        );

        const data: GetConcessionsPublicResponseDto | null = await this.httpClient.get<GetConcessionsPublicResponseDto | null>(uri);
        return data;
    }

    public async getMakes(): Promise<GetMakesResponseDto | null> {
        const uri = this.buildUri(
            "/makes",
            null,
            null
        );

        const data: GetMakesResponseDto | null = await this.httpClient.get<GetMakesResponseDto | null>(uri);
        return data;
    }

    public async getConcessionRecruitmentFeedback(concessionId: string): Promise<ConcessionRecruitmentFeedbackDto | null> {
        const uri = this.buildUri(
            "/concessions/{concessionId}/recruitment-feedback",
            { concessionId: concessionId },
            null
        );

        const data: ConcessionRecruitmentFeedbackDto | null = await this.httpClient.get<ConcessionRecruitmentFeedbackDto | null>(uri);
        return data;
    }

    public async updateConcessionRecruitmentFeedback(concessionId: string, request: UpdateConcessionRecruitmentFeedbackRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/concessions/{concessionId}/recruitment-feedback",
            { concessionId: concessionId },
            null
        );

        await this.httpClient.post<UpdateConcessionRecruitmentFeedbackRequestDto | null, void>(uri, request);
    }
}