import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
    ACCOUNT_URL,
    CONCESSIONS_URL,
    CREATE_ACCOUNT_URL,
    HOME_PAGE_URL,
    LOGIN_URL,
    METIER_URL,
    QUIZ_URL,
    RESET_PASSWORD_URL,
    TEMOIGNAGE_URL,
    TERMS_CONDITIONS_URL,
} from 'Models/Constants';
import ValidatedRoute from 'Components/validated-route/validated-route';
import { Switch, useLocation } from 'react-router-dom';
import BasicLayout from 'Components/basic-layout';
import { Login } from './login';
import ResetPassword from './reset-password';
import CreateAccount from './create-account';
import Quiz from './quiz';
import Concessions from './concessions/decouverte';
import ConcessionDetails from './concessions/id';
import Industrie from './industrie';
import { useStores } from 'Hooks';
import Metiers from './metiers';
import MetierDetails from './metier-details';
import Account from './account';
import TemoignageList from './temoignage-list';
import TemoignagesDetails from './temoignage-details';
import NotFound from './not-found';
import TermsConditions from './terms-conditions';

const Routes: React.FunctionComponent = observer(() => {
    const { userStore, authenticationStore, authorizationStore, deploymentCacheStore } =
        useStores();
    const location = useLocation();

    useEffect(() => {
        if (
            userStore.userInfo === null &&
            authenticationStore.accessToken &&
            authenticationStore.userId
        ) {
            userStore.setUserInfo(authenticationStore.userId);
        }
    }, [
        userStore.userInfo,
        authenticationStore.accessToken,
        authenticationStore.userId,
        userStore,
    ]);

    useEffect(() => {
        // Reset forbidden and notFound state on route change.
        authorizationStore.resetForbidden();
        authorizationStore.resetNotFound();
    }, [location, authorizationStore]);

    useEffect(() => {
        deploymentCacheStore.checkForVersionUpdate();
    }, [location, deploymentCacheStore]);

    return (
        <Switch>
            <ValidatedRoute exact path={HOME_PAGE_URL}>
                <BasicLayout useWithIndustriePage>
                    <Industrie />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={LOGIN_URL}>
                <BasicLayout>
                    <Login />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={RESET_PASSWORD_URL}>
                <BasicLayout>
                    <ResetPassword />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={CREATE_ACCOUNT_URL}>
                <BasicLayout>
                    <CreateAccount />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={QUIZ_URL}>
                <BasicLayout>
                    {/* <NavigationBlocker> */}
                    <Quiz />
                    {/* </NavigationBlocker> */}
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={METIER_URL}>
                <BasicLayout>
                    <Metiers />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={METIER_URL + '/:metier'}>
                <BasicLayout>
                    <MetierDetails />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={CONCESSIONS_URL}>
                <BasicLayout>
                    <Concessions />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={CONCESSIONS_URL + '/:id'}>
                <BasicLayout>
                    <ConcessionDetails />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={TEMOIGNAGE_URL}>
                <BasicLayout>
                    <TemoignageList />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={TEMOIGNAGE_URL + '/:id'}>
                <BasicLayout>
                    <TemoignagesDetails />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute isProtected exact path={ACCOUNT_URL}>
                <BasicLayout>
                    <Account />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={TERMS_CONDITIONS_URL}>
                <BasicLayout>
                    <TermsConditions />
                </BasicLayout>
            </ValidatedRoute>

            <ValidatedRoute>
                {/* must be last route*/}
                <BasicLayout>
                    <NotFound />
                </BasicLayout>
            </ValidatedRoute>
        </Switch>
    );
});

export default Routes;
