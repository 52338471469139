import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { GetConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicRequestDto';
import { useService, useWindowDimensions } from 'Hooks';
import { ConcessionService } from 'Services/ConcessionService';
import { useFetch } from 'Hooks/use-fetch';
import { GetConcessionsPublicItemResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicItemResponseDto';
import FilterStore from 'Stores/FilterStore';
import './index.less';
import debounce from 'lodash.debounce';
import { DEBOUNCE_DELAY_400 } from 'Models/Constants';
import { autorun } from 'mobx';
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import ConcessionInfoMapOverlay from './components/concession-info-map-overlay';
import BlueCluster from './components/blueCluster.png';
import BlueMarker from './components/blueMarker.png';
import RedMarker from './components/redMarker.png';
import UserPositionMarker from './components/userPositionMarker.png';
import Button from 'Components/button';
import { useTranslation } from 'react-i18next';
import { ConcessionViewBaseProps } from 'Routes/concessions/decouverte';
import ConcessionFilters from '../../filters';
import Modal from 'Components/modal';
import ConcessionInfoContent from './components/concession-info-content';
import { CloseIcon } from 'Components/icons';
import useConcessionsUrlParams from 'Hooks/use-concessions-url-params';

//https://www.npmjs.com/package/@react-google-maps/api
//https://tomchentw.github.io/react-google-maps/
//https://developers.google.com/maps/documentation/javascript/tutorials
const ConcessionMapView: FunctionComponent<ConcessionViewBaseProps> = observer(
    ({ userPosition }) => {
        const { windowWidth } = useWindowDimensions();
        const { t } = useTranslation();
        const concessionService = useService(ConcessionService);
        const filterStoreRef = useRef(new FilterStore());
        useConcessionsUrlParams(filterStoreRef.current);
        const { apiRequest } = useFetch();
        const MOBILE_BREAKPOINT = 700;

        const mapRef = useRef<any>();
        const { isLoaded: googleMapScriptIsLoaded } = useJsApiLoader({
            id: 'google-map-script',
            googleMapsApiKey: window.Environment.REACT_APP_GOOGLE_API_KEY ?? '',
            // mapIds: [] see if required
        });

        const [map, setMap] = useState<google.maps.Map | undefined>();

        const [concessions, SetConcessions] = useState<GetConcessionsPublicItemResponseDto[]>([]);
        const [selectedConcessionIds, setSelectedConcessionIds] = useState<string[]>([]);

        const DEFAULT_ZOOM = 10;
        const MAX_ZOOM_IN = 22;
        const DEFAULT_MONTREAL_CENTER = { lat: 45.486589, lng: -73.654642 };
        const [mapCenter, setMapCenter] = useState(DEFAULT_MONTREAL_CENTER);
        const [concessionModalVisible, setConcessionModalVisible] = useState(false);

        const fetchConcessions = useCallback(
            async (searchTerm?: string, makes?: string[]) => {
                const request: GetConcessionsPublicRequestDto = {
                    searchTerm,
                    makes,
                };

                const [concessions] = await apiRequest({
                    requestFunction: (request) => concessionService.getConcessionsPublic(request),
                    requestParameters: request,
                });

                SetConcessions(
                    concessions
                        .filter((x) => x.latitude !== null && x.longitude !== null)
                        .map((x) => ({ ...x, key: x.id, lat: x.latitude, lng: x.longitude }))
                );
            },
            [concessionService, apiRequest]
        );

        const debounceSearch = useRef(
            debounce((params: { searchTerm?: string; makes?: string[] }) => {
                fetchConcessions(params.searchTerm, params.makes);
            }, DEBOUNCE_DELAY_400)
        );

        useEffect(() => {
            const disposer = autorun(() => {
                const filterStore = filterStoreRef.current;
                debounceSearch.current({
                    searchTerm: filterStore.searchTerm,
                    makes: filterStore.makes?.map((x) => x.value),
                });
            });

            return (): void => {
                disposer();
            };
        }, [debounceSearch]);

        //help performance and updating clusters after filter change
        const clustererRef = useRef<any>();
        useEffect(() => {
            if (clustererRef.current) {
                clustererRef.current?.repaint();
            }
        }, [concessions.length]);

        const handleMarkerClick = (concession: GetConcessionsPublicItemResponseDto, position) => {
            if (!selectedConcessionIds.some((x) => x === concession.id)) {
                setSelectedConcessionIds([concession.id!]);
                setMapCenter(position);
            }
            if (windowWidth <= MOBILE_BREAKPOINT) setConcessionModalVisible(true);
        };

        useEffect(() => {
            if (userPosition) {
                setMapCenter({ lat: userPosition.lat, lng: userPosition.lng });
            }
        }, [userPosition]);

        return (
            <div className="ConcessionMapView">
                <ConcessionFilters filterStore={filterStoreRef.current} />

                <div className="divider" />

                {googleMapScriptIsLoaded && (
                    <GoogleMap
                        ref={mapRef}
                        mapContainerClassName="map-container"
                        center={mapCenter}
                        zoom={DEFAULT_ZOOM}
                        options={{
                            gestureHandling:
                                windowWidth > MOBILE_BREAKPOINT ? 'greedy' : 'cooperative',
                            disableDefaultUI: true,
                            zoomControl: true,
                            //remove all points of interest google adds to map so not to be so busy
                            styles: [
                                {
                                    elementType: 'labels',
                                    featureType: 'poi',
                                    stylers: [{ visibility: 'off' }],
                                },
                            ],
                        }}
                        onLoad={(map) => setMap(map)}
                    >
                        {selectedConcessionIds.length > 0 && windowWidth > MOBILE_BREAKPOINT && (
                            <ConcessionInfoMapOverlay
                                concessionIds={selectedConcessionIds}
                                onClose={() => setSelectedConcessionIds([])}
                            />
                        )}

                        {userPosition && (
                            <Button
                                className="center-location-btn"
                                text={t('me_localiser')}
                                type="white"
                                leftIcon="GooglemapMylocationIcon"
                                width="hugged"
                                onClick={() => {
                                    if (map) {
                                        map.panTo(userPosition);
                                    }
                                }}
                            />
                        )}

                        <MarkerClusterer
                            averageCenter
                            enableRetinaIcons
                            gridSize={60}
                            onLoad={(clusterer) => (clustererRef.current = clusterer)}
                            //overriding the default cluster icons.
                            styles={[
                                { textColor: 'white', height: 30, url: BlueCluster, width: 30 },
                                { textColor: 'white', height: 30, url: BlueCluster, width: 30 },
                                { textColor: 'white', height: 30, url: BlueCluster, width: 30 },
                                { textColor: 'white', height: 30, url: BlueCluster, width: 30 },
                                { textColor: 'white', height: 30, url: BlueCluster, width: 30 },
                            ]}
                            onClick={(cluster) => {
                                if (
                                    mapRef.current &&
                                    mapRef.current.state.map.getZoom() === MAX_ZOOM_IN
                                ) {
                                    //cannot de-cluster anymore. all the concessions in this cluster must open in the details. Id is in getTitle
                                    const concessionIds = cluster
                                        .getMarkers()
                                        .map((marker) => marker.getTitle())
                                        .filter((x) => x !== undefined && x !== null)
                                        .map((x) => x!);
                                    setSelectedConcessionIds(concessionIds);
                                    if (windowWidth <= MOBILE_BREAKPOINT)
                                        setConcessionModalVisible(true);
                                }
                            }}
                        >
                            {(clusterer) =>
                                concessions.map((concession, index) => {
                                    const position = {
                                        lat: concession.latitude ?? 0,
                                        lng: concession.longitude ?? 0,
                                    };
                                    return (
                                        <Marker
                                            key={index}
                                            position={position}
                                            clusterer={clusterer}
                                            noClustererRedraw
                                            onClick={() => handleMarkerClick(concession, position)}
                                            icon={
                                                selectedConcessionIds[0] === concession.id
                                                    ? RedMarker
                                                    : BlueMarker
                                            }
                                            options={{
                                                //only way I found to have data related to marker.
                                                //This is used if multiple markers are same position and opening multi detail
                                                title: concession.id,
                                            }}
                                        />
                                    );
                                }) as any
                            }
                        </MarkerClusterer>

                        {userPosition && (
                            <Marker position={userPosition} icon={UserPositionMarker} />
                        )}
                    </GoogleMap>
                )}

                {concessionModalVisible && selectedConcessionIds.length > 0 && (
                    <Modal
                        visible={concessionModalVisible}
                        closeIcon={
                            <CloseIcon
                                fill="black"
                                onClick={() => {
                                    setConcessionModalVisible(false);
                                    setSelectedConcessionIds([]);
                                }}
                            />
                        }
                    >
                        <ConcessionInfoContent concessionIds={selectedConcessionIds} />
                    </Modal>
                )}
            </div>
        );
    }
);

export default ConcessionMapView;
