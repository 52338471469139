import { ConcessionProxy } from 'Api/Features/Concessions/ConcessionProxy';
import { ApiService } from './ApiService';
import { inject } from 'aurelia-dependency-injection';
import { GetMakesResponseDto } from 'Api/Features/Concessions/Dtos/GetMakesResponseDto';
import { GetConcessionsRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsRequestDto';
import { ConcessionDto } from 'Api/Features/Concessions/Dtos/ConcessionDto';
import { GetConcessionsResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsResponseDto';
import { GetConcessionsPublicResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicResponseDto';
import { GetConcessionsPublicRequestDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicRequestDto';
import { GetConcessionsPublicItemResponseDto } from 'Api/Features/Concessions/Dtos/GetConcessionsPublicItemResponseDto';
import { ConcessionPublicDetailsDto } from 'Api/Features/Concessions/Dtos/ConcessionPublicDetailsDto';

@inject(ConcessionProxy)
export class ConcessionService extends ApiService {
    constructor(private readonly concessionProxy: ConcessionProxy) {
        super();
    }

    public async getConcessions(
        request: GetConcessionsRequestDto | null
    ): Promise<[ConcessionDto[], number]> {
        const data: GetConcessionsResponseDto | null = await this.concessionProxy.getConcessions(
            request
        );

        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async getConcessionsPublic(
        request: GetConcessionsPublicRequestDto | null
    ): Promise<[GetConcessionsPublicItemResponseDto[], number]> {
        const data: GetConcessionsPublicResponseDto | null =
            await this.concessionProxy.getConcessionsPublic(request);
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async getConcessionPublic(
        concessionId: string
    ): Promise<ConcessionPublicDetailsDto | null> {
        const data: ConcessionPublicDetailsDto | null =
            await this.concessionProxy.getConcessionPublic(concessionId);
        return data;
    }

    public async getMakes(): Promise<GetMakesResponseDto | null> {
        const data: GetMakesResponseDto | null = await this.concessionProxy.getMakes();
        return data;
    }
}
