import { Input } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntlTelInput from 'react-intl-tel-input';
import './index.less';

interface PhoneInputProps {
    onChange: (isValid: boolean, fullNumber?: string, extension?: string) => void;
    startingValue?: string;
    extension?: string;
    formInputName: string;
    formErrors: Map<string, string[]>;
    onFocus?: () => void;
    classNames?: string;
}

const PhoneInput: FunctionComponent<PhoneInputProps> = ({
    onChange,
    startingValue,
    extension,
    formInputName,
    formErrors,
    onFocus,
    classNames,
}) => {
    const [isValid, setIsValid] = useState(true);
    const [fullNum, setFullNum] = useState<string>();
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars*/

    return (
        <div
            className={`PhoneInput ${
                formErrors?.has(formInputName) && 'form-errors'
            } ${classNames}`}
        >
            <IntlTelInput
                containerClassName="intl-tel-input"
                inputClassName={`form-control ${isValid ? '' : 'invalid-number'}`}
                preferredCountries={['ca', 'us']}
                format
                fieldId="phone"
                fieldName="phone"
                defaultValue={startingValue}
                onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                    onChange(isValid, fullNumber, extension);
                    setFullNum(fullNumber);
                }}
                onPhoneNumberBlur={(isValid, value, selectedCountryData, fullNumber) => {
                    setIsValid(isValid || fullNumber.length == 0);
                }}
                onSelectFlag={(currentNumber, selectedCountryData, fullNumber, isValid) => {
                    const valid = isValid || fullNumber.length == 0;
                    onChange(valid, fullNumber, extension);
                    setFullNum(fullNumber);
                }}
                onPhoneNumberFocus={onFocus}
            />

            <Input
                className={`extension ${isValid ? '' : 'invalid-number'}`}
                placeholder="ext."
                onChange={(e) => onChange(isValid, fullNum, e.target.value)}
                value={extension}
            />

            {!isValid && <span className="error-text">{t('invalid_number')}</span>}
        </div>
    );
};

export default React.memo(PhoneInput);
