import { Layout } from 'antd';
import { FunctionComponent, createContext, useEffect, useMemo, useRef, useState } from 'react';
import './index.less';
import Header from 'Components/header';
import Footer from 'Components/footer';
import { useStores } from 'Hooks';
import { observer } from 'mobx-react';
import FeedbackBox from 'Components/feedback-box';
import { useHistory } from 'react-router-dom';
import useHeadDocumentTags from 'Hooks/use-head-document-tags';
import { Helmet } from 'react-helmet-async';

interface BasicLayoutProps {
    useWithIndustriePage?: boolean;
}

interface ScrollContext {
    scrollToTop: () => void;
}

export const ScrollContext = createContext<ScrollContext | undefined>(undefined);

const BasicLayout: FunctionComponent<BasicLayoutProps> = observer(
    ({ useWithIndustriePage, children }) => {
        const { description, title } = useHeadDocumentTags();
        const { industrieIntroStore, userStore } = useStores();
        const { industrieIntroHasBeenScrolled } = industrieIntroStore;
        const history = useHistory();
        const containerRef = useRef<HTMLDivElement>(null);
        const [scrollContext, setScrollContext] = useState<ScrollContext | undefined>(undefined);

        const getBasicLayoutClassName = useMemo(() => {
            if (!useWithIndustriePage) {
                return 'BasicLayout';
            }
            return industrieIntroHasBeenScrolled
                ? 'IndustrieBasicLayout'
                : 'IndustrieBasicLayout IndustrieBasicLayout--blockScrolling';
        }, [useWithIndustriePage, industrieIntroHasBeenScrolled]);

        const showFeedbackBox = useMemo(() => {
            return userStore.userFeedback?.responses?.some((x) => x?.askQuestion);
        }, [userStore.userFeedback?.responses]);

        useEffect(() => {
            scrollToTop();
        }, [history.location.pathname]);

        const scrollToTop = () => {
            if (containerRef.current) {
                containerRef.current.scrollTo({
                    top: 0,
                    // behavior: 'smooth', // not smooth
                });
            }
        };

        useEffect(() => {
            setScrollContext({
                scrollToTop,
            });
        }, []);

        return (
            <div ref={containerRef} className={getBasicLayoutClassName}>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <ScrollContext.Provider value={scrollContext}>
                    <Layout>
                        <Header />
                        <Layout className={`site-layout`}>
                            <div className="site-layout-content">{children}</div>
                            <Footer />
                        </Layout>
                        {showFeedbackBox && <FeedbackBox />}
                    </Layout>
                </ScrollContext.Provider>
            </div>
        );
    }
);

export default BasicLayout;
