import { CandidateDetailsDto } from 'Api/Features/Candidates/Dtos/CandidateDetailsDto';
import Button from 'Components/button';
import GooglePlacesAutoComplete from 'Components/google-places-autocomplete';
import { ChevronRightIcon } from 'Components/icons';
import PhoneInput from 'Components/phone-input';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { ValidatedFormItem } from 'Components/validated-form-item';
import { LabelColor } from 'Components/validated-form-item/validated-form-items';
import { useFormValidation, useService, useStores, useWindowDimensions } from 'Hooks';
import { useAsyncSingleSelectProps } from 'Hooks/use-async-single-select-props';
import { useFetch } from 'Hooks/use-fetch';
import { FORM_GUTTER } from 'Models/Constants';
import { SearchDistanceRadiusEnum } from 'Models/SearchDistanceRadius';
import { UpdateAccountSchema } from 'Schemas/UpdateAccountSchema';
import { CandidateService } from 'Services/CandidateService';
import { fromE164, toE164 } from 'Utils/PhoneNumberUtils';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Copydeck from 'i18n/Copydeck';
import { observer } from 'mobx-react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ProfileFormProps {
    candidate?: CandidateDetailsDto;
    onChangePassword: () => void;
    isSeekingJob: boolean;
}
const ProfileForm: FunctionComponent<ProfileFormProps> = observer(
    ({ candidate, onChangePassword, isSeekingJob }) => {
        const candidatService = useService(CandidateService);

        const { windowWidth } = useWindowDimensions();
        const { apiRequest } = useFetch();
        const { toastStore, userStore, makesStore } = useStores();
        const { t } = useTranslation();
        const [form] = useForm();
        const { asyncSingleSelectProps } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async () => await makesStore.cachedGetMakes(),
            },
            entityToSingleSelectCustomOption: (makeOption: SingleSelectCustomOption) => {
                return {
                    value: makeOption.value,
                    label: makeOption.label,
                } as SingleSelectCustomOption;
            },
            defaultSelectedOption: {
                label: candidate?.favoriteMakes?.[0] ?? '',
                value: candidate?.favoriteMakes?.[0] ?? '',
            },
        });

        const [phoneValue, setPhoneValue] = useState<string | undefined>();
        const [phonenumberIsValid, setPhoneNumberIsValid] = useState(true);

        const [profileInfo, setProfileInfo] = useState<CandidateDetailsDto | undefined>(candidate);
        const [profileErrors, profileValidateForm] = useFormValidation(UpdateAccountSchema, form);
        const [selectedGooglePlaceId, setSelectedGooglePlaceId] = useState<string>(
            profileInfo?.searchLocation?.googlePlaceId || ''
        );
        const [selectedMake, setSelectedMake] = useState<SingleSelectCustomOption | undefined>(
            () => {
                if (profileInfo?.favoriteMakes) {
                    return {
                        value: profileInfo?.favoriteMakes[0] ?? '',
                        label: profileInfo?.favoriteMakes[0]?.split(/(?=[A-Z])/).join(' ') ?? '',
                    };
                }
                return undefined;
            }
        );

        const [selectedDistance, setSelectedDistance] = useState<SingleSelectCustomOption>(() => {
            return {
                value: `${profileInfo?.searchRadius} km`,
                label: `${profileInfo?.searchRadius} km`,
            };
        });

        const validateProfile = async () => {
            const formValues = form.getFieldsValue();
            const validationObject: CandidateDetailsDto = {
                ...formValues,
                searchRadius: selectedDistance?.value.split('km')[0],
                favoriteMakes: [selectedMake?.value],
                searchLocationGooglePlaceId: selectedGooglePlaceId,
                email: profileInfo?.email,
                isNewBlood: candidate?.isNewBlood ?? true,
                isSeekingJob,
            };

            if (!phonenumberIsValid || !(await profileValidateForm(validationObject))) return;
            setProfileInfo({ id: profileInfo?.id, ...validationObject });
            try {
                await apiRequest({
                    requestFunction: (request) =>
                        candidatService.updateCandidate(profileInfo?.id || '', request),
                    requestParameters: validationObject,
                    useGlobalLoading: true,
                    throwOnError: true,
                    useDefaultSuccessToast: true,
                });
                if (userStore.userInfo) {
                    if (
                        userStore.userInfo.isSeekingJob &&
                        validationObject.isSeekingJob === false
                    ) {
                        userStore.setUserFeedbackAskEmbaucheQuestion();
                    }
                    userStore.userInfo.isSeekingJob = isSeekingJob;
                }
                await userStore.setUserInfo(profileInfo?.id || '');
            } catch (error) {
                toastStore.genericError();
            }
        };

        useEffect(() => {
            if (candidate) {
                setPhoneValue(candidate.phoneNumber ?? undefined);
            }
        }, [candidate]);

        const colSpan = useMemo(() => (windowWidth > 640 ? 12 : 24), [windowWidth]);

        return (
            <>
                <Form layout="vertical" className="form" form={form}>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={t('prenom')}
                                name="firstName"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                initialValue={profileInfo?.firstName}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                                className="AccountForm__input"
                            >
                                <Input className="AccountForm__input" />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={t('nom')}
                                name="lastName"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                initialValue={profileInfo?.lastName}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                                className="AccountForm__input"
                            >
                                <Input className="AccountForm__input" />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={t('telephone')}
                                name="phoneNumber"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                                className="AccountForm__input"
                            >
                                <PhoneInput
                                    formErrors={profileErrors}
                                    onChange={(
                                        isValid: boolean,
                                        fullNumber?: string,
                                        extension?: string
                                    ) => {
                                        const value = toE164(fullNumber, extension);
                                        form.setFieldsValue({
                                            phoneNumber: value,
                                        });
                                        setPhoneValue(value);
                                        setPhoneNumberIsValid(isValid);
                                    }}
                                    startingValue={fromE164(candidate?.phoneNumber).number}
                                    extension={fromE164(phoneValue).ext}
                                    formInputName="phoneNumber"
                                    classNames="AccountForm__input"
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={t('User.marque_favorite')}
                                name="favoriteMakes"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                initialValue={profileInfo?.favoriteMakes}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                                className="AccountForm__input"
                            >
                                <AsyncSingleSelect
                                    {...asyncSingleSelectProps}
                                    onChange={(option?: SingleSelectCustomOption) => {
                                        if (option) {
                                            setSelectedMake(option);
                                        }
                                    }}
                                    selected={selectedMake?.value}
                                    isSearchable={false}
                                    placeholder={''}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={t('User.ville_recherche')}
                                name="searchLocationGooglePlaceId"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                                className="AccountForm__input"
                            >
                                <GooglePlacesAutoComplete
                                    onChange={(value?: string) => {
                                        if (value) {
                                            setSelectedGooglePlaceId(value);
                                        }
                                    }}
                                    hasError={profileErrors.has('searchLocationGooglePlaceId')}
                                    defaultValue={{
                                        label: profileInfo?.searchLocation?.description || '',
                                        value: profileInfo?.searchLocation?.googlePlaceId || '',
                                    }}
                                />
                            </ValidatedFormItem>
                        </Col>
                        <Col span={colSpan}>
                            <ValidatedFormItem
                                label={t('User.rayon_recherche')}
                                name="searchRadius"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                                className="AccountForm__input"
                            >
                                <StaticSingleSelect
                                    options={[
                                        {
                                            value: SearchDistanceRadiusEnum.five,
                                            label: SearchDistanceRadiusEnum.five,
                                        },
                                        {
                                            value: SearchDistanceRadiusEnum.ten,
                                            label: SearchDistanceRadiusEnum.ten,
                                        },
                                        {
                                            value: SearchDistanceRadiusEnum.twentyfive,
                                            label: SearchDistanceRadiusEnum.twentyfive,
                                        },
                                        {
                                            value: SearchDistanceRadiusEnum.fifty,
                                            label: SearchDistanceRadiusEnum.fifty,
                                        },
                                    ]}
                                    onChange={(value?: SingleSelectCustomOption) => {
                                        setSelectedDistance(() => value || selectedDistance);
                                    }}
                                    selected={selectedDistance?.value}
                                    placeholder={''}
                                />
                            </ValidatedFormItem>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <ValidatedFormItem
                                label={t('User.description_a_propos')}
                                name="description"
                                labelColor={LabelColor.grey}
                                errors={profileErrors}
                                initialValue={profileInfo?.description}
                                labelHtmlTag="h2"
                                labelClassName="text-body-small"
                            >
                                <Input.TextArea className="AccountForm__textArea" />
                            </ValidatedFormItem>
                        </Col>
                    </Row>
                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <div className="AccountForm__changePassword">
                                <h2 className="text-body-small">
                                    {Copydeck.accountAccountFormPassword}
                                </h2>
                                <button
                                    onClick={() => onChangePassword()}
                                    className="AccountForm__changePasswordButton"
                                >
                                    {Copydeck.accountAccountFormModifyPassword}
                                    <ChevronRightIcon width={20} height={20} />
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>

                <div className="AccountForm__profileButtonContainer">
                    <Button
                        text={Copydeck.accountAccountFormSubmit}
                        type="red"
                        onClick={() => validateProfile()}
                        uppercase
                    />
                </div>
            </>
        );
    }
);

export default ProfileForm;
